"use client";
import HomeSEO from "@src/SEO/Home.seo";
import { Header, MainBody } from "@src/component";
import { useEffect } from "react";

export default function Home() {
    return (
        <>
            <Header />
            <MainBody />
        </>
    );
}